<template>
<div class="hero-case-study">
  <LetsChat></LetsChat>
  <div class="grid-container">
    <div class="hero__content logo-fyber">
      <img src="@/assets/casestudy/fyber-logo.svg" alt="Fyber">
      <span class="sep"></span>
      <p>Fyber N.V. is a leading advertising technology company. It empowers app developers and digital publishers to generate business-critical revenue streams with targeted advertising, enabling them to optimize the yield they generate from advertising. The Company’s technology infrastructure reaches more than one billion monthly active users, providing a channel-neutral open-access platform for advertisers and publishers. It enables cross-device advertising with a global reach and a strong focus on video. <br>
        Fyber N.V. was founded in 2010 and is headquartered in Berlin, Germany. The Company employs more than 380 talented people globally and is listed on the Prime Standard of Frankfurt Stock Exchange under the symbol ‘FBR.’</p>
    </div>
  </div>

  <div class="laptop-img fyber-laptop">
    <div class="grid-container">
      <img src="@/assets/casestudy/is-main-img.png" alt="Investor Section">
    </div>
  </div>

  <div class="container-about about-investors">
    <div class="grid-container">
      <img src="@/assets/casestudy/investors-diamond.svg" alt="Fyber">
      <h3>About the project</h3>
      <p>Investors Section connects app developers and media companies with advertisers through the power of technology. Across every device. We are an independent advertising technology company devoted to delivering global audiences at scale through a powerful cross-platform monetization & advertising solution. Our SSP, Ad Server, Exchange and Mediation products empower thousands of the world’s leading app developers and publishers to generate business-critical revenue streams and serves over half a billion monthly active users globally.</p>
    </div>
  </div>

  <div class="container-typography">
    <div class="grid-container">
      <div class="container-services__content">
        <h3>What we do</h3>
        <div class="wwd-left">
          <h4>Managed services</h4>
          <img src="@/assets/casestudy/web-app-purple.svg" width="60px" height="60px">
          <h4>Support</h4>
          <img src="@/assets/casestudy/maintenance-icon-purple.svg" width="60px" height="60px">
          <h4>SEO</h4>
          <img src="@/assets/casestudy/consultancy-icon-purple.svg" width="60px" height="60px">
        </div>
        <span class="sep vertical"></span>
        <div class="wwd-right">
          <img src="@/assets/casestudy/managed-services-purple.svg" width="60px" height="60px">
          <h4>Custom Web App's</h4>
          <img src="@/assets/casestudy/support-icon-purple.svg" width="60px" height="60px">
          <h4>Maintenance</h4>
          <img src="@/assets/casestudy/seo-icon-purple.svg" width="60px" height="60px">
          <h4>Consultancy</h4>
        </div>
      </div>
    </div>
  </div>

  <testimonial>
    <div class="testimonial-text">
      <p class="testimonial-title-text">Sabrina Kassmannhuber,  Investor Relations</p>
      <p class="testimonial-text__content">"It is a pleasure to work with Scriptics. Vali and his team are always dependable and responsive & are passionate about providing the highest quality work. Nothing is ever problem with Scriptics - they are keen to help and constantly deliver on time and on budget no matter how tight the deadline."</p>
    </div>
  </testimonial>

  <div class="container-nav-projects">
    <div class="grid-container">
      <div class="container-nav-projects__content">
        <h3>Related projects</h3>

        <div class="container-nav-projects-left">
          <router-link :to="{ name: 'Fyber'}" class="left">
            <img src="@/assets/casestudy/btn-fyber.jpg" alt="Fyber">
          </router-link>
          </div>


        <div class="container-nav-projects-right">
        <router-link :to="{ name:'Pica8'}" class="right">
          <img src="@/assets/casestudy/btn-pica8.jpg" alt="Pica8">
        </router-link>
          </div>
      </div>
    </div>
  </div>

  <ContactUs color="#1a033f"></ContactUs>
  <Footer></Footer>

</div>
</template>

<script>
import Testimonial from "../../components/Testimonial";
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Home/Footer";
import LetsChat from "../../components/LetsChat";
export default {
  name: "FyberInvestors",
  components: {LetsChat, Footer, ContactUs, Testimonial}
}
</script>

<style lang="scss">
.logo-fyber img {
  max-width: 320px;
}
.about-investors {
  background: #1a033f;
}
.fyber-laptop {
  background: #2d2d2d;
}
.container-about h3, .container-about p {
  color: #fafafa;
}
.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #1a033f 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>